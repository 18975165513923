ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.supergens-container {
  display: flex;
  flex-direction: row;

  .search {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    input {
      padding: 10px 15px;
      background-color: #000000;
      font-size: 1.5em;
      color: #CFCFCF;
      border: none;
      outline: 2px solid #242424;
      &:focus {
        outline-offset: 0px;
        outline: 2px solid #242424
      }
    }
  }
  
  .sound-list {
    width: 400px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    ul {
      display: block;
      column-count: 2;
      margin-top: 0px;
    }
    input {
      float: left;
      margin-right: 6px;
    }
  }

  .supergen {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    &.hidden {
      display: none;
    }
    a, a:visited, a:link {
      color: #477A92;
      text-decoration: none;
      margin: 5px 25px 5px 0px;
    }
    .sounds {
      font-size: 11px;
      margin-top: 0px;
      margin-left: 25px;
    }
  } 
}
