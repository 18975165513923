$vin-blue: #5bb7db;
$vin-green: #60b044;

body {
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 300;
  background-color: #242424;
  color: #CFCFCF;
  opacity: 0.9;
}

h2 {
	color: $vin-blue;
}

a, a:visited, a:link {
  text-decoration: none;
  padding: 5px;
  color: $vin-blue;
}

.navigation { 
  color: $vin-green;
  width: 425px;
  padding: 5px 0px 0px 25px;
  background-color: #000000;
  border-top-right-radius: 20px;
  a { 
    color: $vin-green;
    &.active {
      text-decoration: underline;
    }
  } 
}

.side-content {
  padding: 25px;
  background-color: #000000;
  border-bottom-right-radius: 20px;
  width: 400px;
}

.main-content {
  margin: 25px;
}
